import { ApiServiceBaseOperationDescriptor, Member, ModelType, type CloudFlowNodeType } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Button, List, ListItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CopyCodeBlock } from "../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import LoadingButton from "../../../../../Components/LoadingButton";
import { useCloudPermissions } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { useReferenceableNodes } from "../../Common/hooks/useReferenceableNodes";
import { Loader } from "../../../../../Components/Loader";
import { ApiActionParametersForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import { useCallback, useState } from "react";
import CloudSpecificPermissionForm from "../../ApiActionParametersForm/CloudSpecificPermissionForm";
import { WithFirebaseModel } from "@doitintl/models-firestore";

const PermissionForm = ({
  operation,
  permissionFormValues,
  setConfigurationFormValid,
  onConfigurationValuesChange,
  isOperationLoading,
  modelId,
  referenceableNodesLoading,
}: {
  operation: WithFirebaseModel<ApiServiceBaseOperationDescriptor> | null;
  permissionFormValues: object;
  setConfigurationFormValid: (valid: boolean) => void;
  onConfigurationValuesChange: (values: unknown) => void;
  isOperationLoading: boolean;
  modelId: string | null;
  referenceableNodesLoading: boolean;
}) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const permissionsFormModel = operation?.parameters
    ? {
        ...operation.parameters,
        members: {
          ...operation.parameters.members,
          permissionsProjectId: {
            model: {
              type: ModelType.STRING,
            },
          } as Member,
        },
      }
    : null;

  return (
    <Stack
      sx={{
        p: 2,
        justifyContent: "center",
        gap: 2,
      }}
    >
      <Loader loading={isOperationLoading || modelId !== operation?.inputModel || referenceableNodesLoading}>
        {permissionsFormModel && (
          <ApiActionParametersForm
            key={nodeConfig.id}
            inputModel={permissionsFormModel}
            values={permissionFormValues}
            onValidityChange={setConfigurationFormValid}
            onValuesChange={onConfigurationValuesChange}
          >
            <CloudSpecificPermissionForm
              inputModel={permissionsFormModel}
              provider={nodeConfig.parameters.operation.provider}
            />
          </ApiActionParametersForm>
        )}
      </Loader>
    </Stack>
  );
};

const PermissionsTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const { configurationValues } = nodeConfig.parameters;

  const [permissionFormValues, setPermissionFormValues] = useState(configurationValues);

  const { requiredPermissions, command, loading, updatePermissions, isValid } = useCloudPermissions({
    permissionConfigValues: permissionFormValues,
  });

  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const { modelId } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);
  const [, referenceableNodesLoading] = useReferenceableNodes(nodeConfig.id);
  const [configurationFormValid, setConfigurationFormValid] = useState<boolean>(true);

  const onConfigurationValuesChange = useCallback((newValues: unknown) => {
    setPermissionFormValues(newValues as { organization: string; projectId: string; serviceAccount: string });
  }, []);

  const getConsoleLink = (): string => {
    if (nodeConfig.parameters?.provider === "AWS" && nodeConfig.parameters?.configurationValues?.accountId) {
      return `https://${nodeConfig.parameters?.configurationValues.accountId}.signin.aws.amazon.com/console`;
    }
    if (nodeConfig.parameters?.provider === "GCP" && nodeConfig.parameters?.configurationValues?.organization) {
      const orgId = nodeConfig.parameters?.configurationValues?.organization.split("/").pop();
      return `https://console.cloud.google.com/iam-admin/iam?organizationId=${orgId}`;
    }

    return "#";
  };

  if (isValid) {
    return (
      <>
        <PermissionForm
          operation={operation}
          permissionFormValues={permissionFormValues}
          setConfigurationFormValid={setConfigurationFormValid}
          onConfigurationValuesChange={onConfigurationValuesChange}
          isOperationLoading={isOperationLoading}
          modelId={modelId}
          referenceableNodesLoading={referenceableNodesLoading}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
          }}
        >
          <Alert severity="success">
            <Typography variant="body2">Permissions granted</Typography>
          </Alert>
        </Box>
      </>
    );
  }

  if (requiredPermissions.length === 0) {
    return (
      <>
        <PermissionForm
          operation={operation}
          permissionFormValues={permissionFormValues}
          setConfigurationFormValid={setConfigurationFormValid}
          onConfigurationValuesChange={onConfigurationValuesChange}
          isOperationLoading={isOperationLoading}
          modelId={modelId}
          referenceableNodesLoading={referenceableNodesLoading}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
          }}
        >
          <LoadingButton
            variant="outlined"
            sx={{ px: 2, py: 0.875 }}
            onClick={updatePermissions}
            loading={loading}
            disabled={!configurationFormValid}
            mixpanelEventId="cloudflow.check-permissions"
          >
            Check for required permissions
          </LoadingButton>
        </Box>
      </>
    );
  }
  return (
    <>
      <PermissionForm
        operation={operation}
        permissionFormValues={permissionFormValues}
        setConfigurationFormValid={setConfigurationFormValid}
        onConfigurationValuesChange={onConfigurationValuesChange}
        isOperationLoading={isOperationLoading}
        modelId={modelId}
        referenceableNodesLoading={referenceableNodesLoading}
      />

      <Stack
        sx={{
          alignContent: "center",
          p: 2,
          gap: 3,
        }}
      >
        <Alert severity="warning">
          <Typography variant="body2">We require the following permissions in order to run this node: </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
            {requiredPermissions?.map((permission) => (
              <ListItem key={permission} sx={{ display: "list-item", px: 0.5 }}>
                {permission}
              </ListItem>
            ))}
          </List>
        </Alert>
        <Stack
          sx={{
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Run the following command to grant the permission:
          </Typography>
          <CopyCodeBlock base={command} />
          <Button variant="outlined" component="a" href={getConsoleLink()} target="_blank">
            <OpenInNewIcon
              sx={{
                width: 20,
                height: 20,
                mr: 1,
              }}
            />
            Go to {nodeConfig.parameters?.provider} Console
          </Button>
          <LoadingButton
            mixpanelEventId="cloudflow.check-permissions"
            variant="outlined"
            sx={{ px: 2, py: 0.875 }}
            onClick={updatePermissions}
            loading={loading}
          >
            Check for required permissions
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default PermissionsTab;
