import React, { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  type CloudflowDTO,
  type CloudFlowNode,
  type CreateNodeResponseDTO,
  type CreateOrUpdateNodesResponseDTO,
  type DeleteNodesResponseDTO,
} from "../../types";

interface CloudFlowContextValue {
  cloudFlow?: CloudflowDTO;
  cloudFlowNodes: CloudFlowNode[];
  addCloudFlowNode: (payload: CreateNodeResponseDTO) => void;
  deleteCloudFlowNode: (payload: DeleteNodesResponseDTO) => void;
  createOrUpdateCloudFlowNode: (payload: CreateOrUpdateNodesResponseDTO) => void;
  upsertCloudflowNodes: (payload: CreateOrUpdateNodesResponseDTO) => void;
}

const CloudFlowContext = createContext<CloudFlowContextValue | undefined>(undefined);

interface CloudFlowProviderProps {
  flow: CloudflowDTO;
  children: ReactNode;
}

export const CloudFlowProvider: React.FC<CloudFlowProviderProps> = ({ flow, children }) => {
  const [nodes, setNodes] = useState<CloudFlowNode[]>([]);

  useEffect(() => {
    if (flow.nodes) {
      setNodes(flow.nodes);
    }
  }, [flow]);

  const deleteNode = useCallback(
    (payload: DeleteNodesResponseDTO) => {
      setNodes((prevNodes) => {
        const filterDeleted = prevNodes.filter((node) => !payload.deletedNodes?.includes(node.id));

        const newNodes = filterDeleted.map((node) => {
          const updatedNode = payload.updatedNodes.find((updatedNode) => updatedNode.id === node.id);
          return updatedNode || node;
        });
        return newNodes;
      });
    },
    [setNodes]
  );

  const addNode = useCallback(
    (payload: CreateNodeResponseDTO) => {
      setNodes((prevNodes) => {
        const updated = prevNodes.map((node) => {
          const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
          return updatedNode || node;
        });
        return payload.addedNode ? [...updated, payload.addedNode] : updated;
      });
    },
    [setNodes]
  );

  const createOrUpdateNode = useCallback(
    (payload: CreateOrUpdateNodesResponseDTO) => {
      setNodes((prevNodes) => {
        const updated = prevNodes.map((node) => {
          const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
          return updatedNode || node;
        });
        return [...updated, ...payload.addedNodes];
      });
    },
    [setNodes]
  );

  const upsertCloudFlowNodes = useCallback(
    (payload: CreateOrUpdateNodesResponseDTO) => {
      setNodes((prevNodes) => {
        const updated = prevNodes.map((node) => {
          const updatedNode = payload.updatedNodes.find((uNode) => uNode.id === node.id);
          return updatedNode || node;
        });
        return payload.addedNodes ? [...updated, ...payload.addedNodes] : updated;
      });
    },
    [setNodes]
  );

  const contextValue = useMemo<CloudFlowContextValue>(
    () => ({
      cloudFlow: flow,
      cloudFlowNodes: nodes,
      addCloudFlowNode: addNode,
      deleteCloudFlowNode: deleteNode,
      createOrUpdateCloudFlowNode: createOrUpdateNode,
      upsertCloudflowNodes: upsertCloudFlowNodes,
    }),
    [flow, nodes, addNode, deleteNode, createOrUpdateNode, upsertCloudFlowNodes]
  );

  return <CloudFlowContext.Provider value={contextValue}>{children}</CloudFlowContext.Provider>;
};

export const useCloudFlowContext = (): CloudFlowContextValue => {
  const context = useContext(CloudFlowContext);
  if (!context) {
    throw new Error("useCloudFlowContext must be used within a CloudFlowProvider");
  }
  return context;
};
