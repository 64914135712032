import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useParams } from "react-router";
import {
  type AssetModel,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudSolve,
  ContractModel,
  DoitRole,
  IntegrationModel,
  type LookerSku,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import clamp from "lodash/clamp";
import { DateTime } from "luxon";

import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../Context/AuthContext";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { useContractsContext } from "../../../Context/customer/ContractsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { sanitizeDate, sanitizeKeepingLocalDate } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { useBackToList } from "../hooks";
import { fieldRange } from "./const";
import {
  type CommitmentPeriodType,
  type ContractStateType,
  type DiscountPeriodType,
  type VendorContract,
} from "./types";
import { formatContractFromFs, ifEditForbidden } from "./utils";

export type ContractFormContextType = {
  state: ContractStateType;
  setState: Dispatch<SetStateAction<ContractStateType>>;
  handleChange: (name: keyof ContractStateType, isNumber?: boolean) => ({ target }: { target: any }) => void;
  handleChangeDate: (
    name: keyof Pick<ContractStateType, "startDate" | "endDate" | "discountEndDate">
  ) => (date: DateTime | null) => void;
};

export const defaultVendorContractValues = {
  credits: {},
  errors: {},
  assets: [],
  commitmentPeriods: [],
  commitmentType: null,
};

export const lookerSkuDefaultValues: LookerSku = {
  skuName: { label: "", monthlyListPrice: 0, googleSku: "" },
  months: 0,
  quantity: 0,
  monthlySalesPrice: 0,
};

export const stateDefaultValues: ContractStateType = {
  activeStep: 0,
  loading: false,
  editMode: false,
  isEditForbidden: false,
  overlappingContractExists: false,
  errors: {} as any,

  type: "",
  entity: "",
  accountManager: "",
  discount: 0,
  discountEndDate: null as DateTime | null,
  estimatedValue: 0,
  startDate: sanitizeDate(DateTime.utc()),
  endDate: null as DateTime | null,
  contractFile: null,
  isCommitment: false,
  isSoftCommitment: false,
  commitmentRollover: false,
  commitmentPeriods: [] as CommitmentPeriodType[],
  discountPeriods: [] as DiscountPeriodType[],
  isRenewal: false,
  active: true,
  terminated: false,
  notes: "",
  purchaseOrder: "",
  assets: [] as ModelReference<AssetModel>[],
  partnerMargin: null,
  credits: {},
  vendorContract: { ...defaultVendorContractValues } as unknown as VendorContract,

  // AWS
  disableNextFromAwsSupport: false,
  selectedPricebooks: {},
  support: null,

  // Google Cloud
  pricelistSheet: null,
  rebaseModifier: 0,
  discountPreemptible: false,
  gcpFlexSaveOverwrite: fieldRange.gcpFlexSaveOverwrite.default,
  gcpSupport: undefined,

  // GSuite
  specialDiscount: null,

  // Standalone
  userEmail: "",

  // Looker
  lookerSkus: [{ ...lookerSkuDefaultValues }],
  lookerSalesProcess: "",
  lookerInvoiceFrequency: 1,
  lookerContractDuration: 12,

  // Google Cloud partner led premium support
  gcpContract: [] as ModelReference<ContractModel>[],
  plpsPercent: 0,

  // DoiT Cloud Navigator/Solve
  tierId: "",
  commitmentMonths: undefined,
  paymentTerm: "monthly",
  chargePerTerm: 0,
  monthlyFlatRate: 1,
  currency: "USD",
  pointOfSale: "doit",
};

const ContractFormContext = createContext<ContractFormContextType>({
  state: stateDefaultValues,
  handleChange: () => () => {},
  handleChangeDate: () => () => {},
  setState: () => {},
});

export const ContractFormContextProvider = ({ children }) => {
  const [state, setState] = useState(stateDefaultValues);
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer, entities } = useCustomerContext();
  const { contractId } = useParams<{ customerId: string; contractId: string }>();
  const handleBackToList = useBackToList();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const { contracts } = useContractsContext();

  useEffect(() => {
    const loadContract = async () => {
      const contract = (await getCollection(ContractModel).doc(contractId).get()).asModelData();

      const vendorContract =
        contract?.vendorContract && isDoitEmployee ? (await contract.vendorContract.get()).data() : undefined;

      if (!contract) {
        handleBackToList();
        return;
      }

      const customerPricebooksSnapshot = await getCollection(IntegrationModel)
        .doc("cloudhealth")
        .collection("pricebooksAssignments")
        .where("contractId", "==", contractId)
        .get();

      const selectedPricebooks = Object.fromEntries(
        customerPricebooksSnapshot.docs
          .map((doc) => doc.data())
          .map(({ payerId, pricebookId }) => [payerId, pricebookId])
      );

      const contractFields = formatContractFromFs(contract, vendorContract, contract?.vendorContract?.id);

      const isEditForbidden = ifEditForbidden({
        isDoitContractAdmin,
        isDoitContractOwner,
        timeCreated: contract.timeCreated,
        startDate: contract.startDate,
        type: contract.type,
        customer,
        currentUser,
        isActive: contract.active,
      });

      setState((prevState) => ({
        ...prevState,
        editMode: true, // edit mode vs create new doc
        isEditForbidden,
        ...contractFields,
        selectedPricebooks,
      }));
      mixpanel.track("contracts.contract.open", {
        id: contractId,
      });
    };

    if (contractId) {
      loadContract();
    } else {
      const activeEntity = entities.find((entity) => entity.active);
      let accountManager;
      if (customer.accountManager) {
        const id = customer.accountManager.id;
        accountManager = accountManagers?.find((am) => am.id === id);
      }
      setState((prevState) => ({
        ...prevState,
        ...stateDefaultValues,
        entity: activeEntity?.id ?? "",
        accountManager: accountManager?.id ?? "",
      }));
    }
  }, [
    contractId,
    customer,
    handleBackToList,
    currentUser,
    isDoitContractAdmin,
    entities,
    isDoitEmployee,
    isDoitContractOwner,
    accountManagers,
  ]);

  const handleChange = useCallback(
    (name: keyof ContractStateType) =>
      ({ target }) => {
        setState((prevState) => {
          let val: number | string | boolean = target.value;

          if (target.type === "number") {
            val = clamp(Number(target.value ?? 0), fieldRange[name]?.min, fieldRange[name]?.max);
          }

          if (target.type === "checkbox") {
            val = target.checked;
          }

          if (target.type === "radio" && ["true", "false"].includes(val as string)) {
            val = val === "true";
          }

          return {
            ...prevState,
            [name]: val,
            errors: {
              ...prevState.errors,
              [name]: false,
            },
          };
        });
      },
    [setState]
  );

  const handleChangeDate = useCallback(
    (name: keyof Pick<ContractStateType, "startDate" | "endDate" | "discountEndDate">) => (date: DateTime | null) => {
      if (!date) {
        setState((prevState) => ({
          ...prevState,
          [name]: null,
          errors: {
            ...prevState.errors,
            [name]: false,
          },
        }));
        return;
      }

      const today = sanitizeKeepingLocalDate(DateTime.now());
      const sanitizedDate = sanitizeKeepingLocalDate(date);

      if (name === "startDate") {
        const isValidMonth =
          today.year < sanitizedDate.year || (today.year === sanitizedDate.year && today.month <= sanitizedDate.month);

        if (!isDoitContractAdmin && !isValidMonth) {
          setState((prevState) => ({
            ...prevState,
            [name]: stateDefaultValues[name],
            errors: {
              ...prevState.errors,
              [name]: true,
            },
          }));
          return;
        }
      }

      setState((prevState) => {
        const updates: Partial<ContractStateType> = {
          [name]: sanitizedDate,
          errors: {
            ...prevState.errors,
            [name]: false,
          },
        };

        if (name === "startDate" && state.commitmentMonths && state.commitmentMonths > 0) {
          updates.endDate = sanitizedDate.plus({ months: state.commitmentMonths });
          updates.errors = {
            ...updates.errors,
            endDate: false,
          };
        }

        return {
          ...prevState,
          ...updates,
        };
      });
    },
    [isDoitContractAdmin, state.commitmentMonths]
  );

  useEffect(() => {
    if (![AssetTypeDoiTCloudNavigator, AssetTypeDoiTCloudSolve].includes(state.type)) {
      setState((prevState) => ({
        ...prevState,
        overlappingContractExists: false,
      }));
      return;
    }

    const filteredContracts = contracts.filter(
      (x) => x.type === state.type && x.active && (state.editMode ? x.id !== contractId : true)
    );
    const newContractStartDate = state.startDate?.toJSDate();
    const newContractEndDate = state.endDate?.toJSDate();

    let hasOverlap = false;
    for (const contract of filteredContracts) {
      const existingContractStartDate = contract.startDate.toDate();
      const existingContractEndDate = contract.endDate?.toDate();

      // Scenario 1: New contract start date is between existing contract start and end date
      if (
        newContractStartDate >= existingContractStartDate &&
        (!existingContractEndDate || newContractStartDate < existingContractEndDate)
      ) {
        hasOverlap = true;
        break;
      }

      // Scenario 2: Existing contract start date is between new contract start and end date
      if (
        existingContractStartDate >= newContractStartDate &&
        (!newContractEndDate || existingContractStartDate < newContractEndDate)
      ) {
        hasOverlap = true;
        break;
      }
    }

    setState((prevState) => ({
      ...prevState,
      overlappingContractExists: hasOverlap,
    }));
  }, [contractId, contracts, state.editMode, state.endDate, state.startDate, state.type]);

  const values = useMemo(
    () => ({ state, handleChange, setState, handleChangeDate }),
    [state, handleChange, handleChangeDate, setState]
  );

  return <ContractFormContext.Provider value={values}>{children}</ContractFormContext.Provider>;
};

export const ContractFormContextProviderForTesting = ({
  children,
  value,
}: {
  children?: React.ReactNode;
  value?: Partial<Omit<ContractFormContextType, "state">> & { state?: Partial<ContractFormContextType["state"]> };
}) => {
  const actualValue = useMemo(() => value ?? {}, [value]);

  actualValue.state = { ...stateDefaultValues, ...value?.state };
  actualValue.handleChange = value?.handleChange ?? (() => () => {});
  actualValue.handleChangeDate = value?.handleChangeDate ?? (() => () => {});
  actualValue.setState = value?.setState ?? (() => {});

  return <ContractFormContext.Provider value={actualValue as any}>{children}</ContractFormContext.Provider>;
};

export const useContractFormContext = () => useContext(ContractFormContext);
