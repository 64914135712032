import { useCallback, useEffect, useMemo, useState } from "react";

import {
  CloudflowEngineModel,
  type CloudflowEntityModel,
  type CloudflowExecutionModel,
  CloudflowExecutionStatus,
  FeatureDemandModel,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  useDocumentData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { useQuery } from "@tanstack/react-query";
import { type AxiosResponse } from "axios";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { useRoles } from "../../Components/hooks/IAM/useRoles";
import { useUsers } from "../../Components/hooks/IAM/useUsersOrInvites";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { FeatureDemands } from "../../constants/featureDemands";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { type CloudflowExecutionSnap, type CloudflowWSnap } from "../../types/Cloudflow";
import { consoleErrorWithSentry } from "../../utils";
import { getCachingKeys } from "../../utils/cachingKeys";
import useSegmentTrackEvent from "../../utils/useSegmentTrackEvent";
import {
  createCloudflow,
  createCloudflowFromTemplate,
  createOrUpdateNode,
  deleteCloudflow,
  deleteNode,
  getAwsRegions,
  getCloudflow,
  getGcpProjects,
  publishCloudflow,
  triggerCloudflow,
  unpublishCloudflow,
  updateCloudflow,
  updateCloudflowNodes,
} from "./api";
import {
  type CloudflowCreatedEvent,
  type CloudflowDTO,
  type CloudflowPublishedEvent,
  type CloudflowTemplate,
  type CreateCloudflowInput,
  type CreateNodeResponseDTO,
  type CreateOrUpdateNode,
  type CreateOrUpdateNodesResponseDTO,
  type DeleteCloudflowInput,
  type DeleteNodesResponseDTO,
  type NodeCreatedEvent,
  type NodeEditedEvent,
  type NodeTestedEvent,
  type ProjectInfo,
  type TriggerCloudflowResponce,
  type UpdateCloudflowInput,
  type UpdateCloudflowNodes,
} from "./types";
import { createBaseNodeEvent } from "./utils";

export const useRegisteredInterest = () => {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext();
  const featureDemandCollection = getCollection(FeatureDemandModel);

  const [demands, demandsLoading] = useCollectionData(
    featureDemandCollection.where("feature", "==", FeatureDemands.CLOUDFLOW).where("customer", "==", customer.ref)
  );

  const onRegisterInterest = useCallback(async () => {
    if (!userModel) {
      throw new Error("User not found");
    }
    await featureDemandCollection.add({
      feature: FeatureDemands.CLOUDFLOW,
      customer: customer.ref,
      data: {
        user: userModel.ref,
        time: DateTime.now().toString(),
      },
    });
  }, [customer.ref, featureDemandCollection, userModel]);

  const isRegistered = !!demands?.length;
  const tenantHasAlreadyRegisteredInterest =
    isRegistered && !demands?.find((demand) => demand.data?.user.id === userModel?.id);

  return {
    onRegisterInterest,
    isRegistered: { customer: isRegistered, user: !tenantHasAlreadyRegisteredInterest },
    isRegisteredLoading: demandsLoading,
  };
};

const findWorkflowOwner = (collaborators?: { role: string; email: string }[]) =>
  collaborators?.find((collaborator) => collaborator.role === "owner")?.email || "";

export const useCloudflows = () => {
  const { customer } = useCustomerContext();

  const cloudflowTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowWSnap => {
      const owner = findWorkflowOwner(data.collaborators);
      return {
        data: { ...data, owner, customer: customer.ref },
        ref: snapshot.ref,
      };
    },
    [customer.ref]
  );

  const cloudflowsRef = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowEntities")
        .where("customer", "==", customer.ref),
    [customer.ref]
  );

  const [cloudflows, cloudflowsLoading] = useCollectionData(customer ? cloudflowsRef : undefined, {
    transform: cloudflowTransform,
  });

  return { cloudflows, cloudflowsLoading };
};

export const useCloudflow = (flowId: string) => {
  const { customer } = useCustomerContext();
  const { roles } = useRoles();
  const { users } = useUsers(roles);

  const cloudflowTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowWSnap => {
      const owner = users?.find((user) => user.id === data.createdBy.id)?.email || "";
      return {
        data: { ...data, owner, customer: customer.ref },
        ref: snapshot.ref,
      };
    },
    [customer.ref, users]
  );

  const cloudflowDocRef = useMemo(
    () => getCollection(CloudflowEngineModel).doc("cloudflows").collection("cloudflowEntities").doc(flowId),
    [flowId]
  );

  const [cloudflow, cloudflowLoading] = useDocumentData(customer ? cloudflowDocRef : undefined, {
    caching: true,
    cachingKeys: getCachingKeys(customer.id),
    transform: cloudflowTransform,
  });

  return { cloudflow, cloudflowLoading };
};

export const useCloudflowTemplates = () => {
  const templateTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowEntityModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowEntityModel> | DocumentSnapshotModel<CloudflowEntityModel>
    ): CloudflowTemplate => ({
      id: snapshot.id,
      name: data.name,
      description: data.description,
      integrations: [],
      tags: data.tags,
      ref: snapshot.ref,
    }),
    []
  );

  const templatesRef = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowEntities")
        .where("type", "==", "preset"),
    []
  );

  const [templates, templatesLoading] = useCollectionData(templatesRef, {
    transform: templateTransform,
  });

  return { templates, templatesLoading };
};

export const useDeleteCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const deleteCloudFlow = useCallback(
    async (customerId: string, deleteCloudflowInput: DeleteCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      setLoading(true);
      try {
        res = await deleteCloudflow(api, customerId, deleteCloudflowInput);
        successSnackbar("CloudFlow successfully deleted");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to delete CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [deleteCloudFlow, loading] as const;
};

export const useCreateOrUpdateNode = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, flowId: string, data: CreateOrUpdateNode) => {
      let res: AxiosResponse<CreateNodeResponseDTO> | undefined;
      try {
        setLoading(true);
        res = await createOrUpdateNode(api, customerId, flowId, data);
        successSnackbar("Step successfully added");

        track("Node Created", { flowId, ...createBaseNodeEvent(data.node) });
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to add Step");
      } finally {
        setLoading(false);
      }
      return res?.data;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [create, loading] as const;
};

export const useDeleteNode = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const remove = useCallback(
    async (customerId: string, flowId: string, nodeId: string) => {
      let res: AxiosResponse<DeleteNodesResponseDTO> | undefined;
      try {
        setLoading(true);
        res = await deleteNode(api, customerId, flowId, nodeId);
        successSnackbar("Step successfully deleted");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to delete step");
      } finally {
        setLoading(false);
      }
      return res?.data;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [remove, loading] as const;
};

export const useCreateCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, createCloudflowInput: CreateCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await createCloudflow(api, customerId, createCloudflowInput);
        successSnackbar("CloudFlow successfully created");
        track("Flow Created", { flowId: res.data.id, source: "new" });
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to create CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [create, loading] as const;
};

export const useCreateCloudflowFromTemplate = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const create = useCallback(
    async (customerId: string, template: CloudflowTemplate) => {
      let res: AxiosResponse<{ id: string }> | undefined;
      try {
        setLoading(true);
        res = await createCloudflowFromTemplate(api, customerId, template.id);
        successSnackbar("CloudFlow successfully created from template");

        track("Flow Created", {
          flowId: res.data.id,
          source: "template",
          templateId: template.id,
          cloudProvider: Object.keys(template.tags || {}),
        });
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to create CloudFlow from template");
      } finally {
        setLoading(false);
      }
      return res?.data;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [create, loading] as const;
};

export const useUpdateCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const update = useCallback(
    async (customerId: string, flowId: string, updateCloudflowInput: UpdateCloudflowInput) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await updateCloudflow(api, customerId, flowId, updateCloudflowInput);
        successSnackbar("CloudFlow successfully updated");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to update CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [update, loading] as const;
};

export const useUpdateCloudflowNodes = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const updateNodes = useCallback(
    async (customerId: string, nodes: UpdateCloudflowNodes) => {
      let res: AxiosResponse<CreateOrUpdateNodesResponseDTO> | undefined;
      try {
        setLoading(true);
        res = await updateCloudflowNodes(api, customerId, nodes);
        successSnackbar("CloudFlow nodes successfully updated");

        if (nodes.updatedNodes) {
          for (const node of nodes.updatedNodes) {
            track("Node Edited", {
              flowId: nodes.flowId,
              hadIssues: Object.keys(node.node.errorMessages ?? {}).length > 0,
              actionType: "edit",
              ...createBaseNodeEvent(node.node),
            });
          }
        }
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to update CloudFlow");
      } finally {
        setLoading(false);
      }
      return res?.data;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [updateNodes, loading] as const;
};

export const useTriggerCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const run = useCallback(
    async (customerId: string, flowId: string) => {
      let res: AxiosResponse<TriggerCloudflowResponce> | undefined;
      try {
        setLoading(true);
        res = await triggerCloudflow(api, customerId, flowId);
        successSnackbar("CloudFlow successfully triggered");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to trigger CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar]
  );

  return [run, loading] as const;
};

export const useCloudflowExecutions = () => {
  const { cloudflows } = useCloudflows();
  const { customer } = useCustomerContext();

  const [cloudflowExecutionsLoading, setCloudflowExecutionsLoading] = useState(true);
  const [cloudflowExecutions, setCloudflowExecutions] = useState<CloudflowExecutionSnap[]>([]);

  const cloudflowExecutionTransform = useCallback(
    (
      data: WithFirebaseModel<CloudflowExecutionModel>,
      snapshot: QueryDocumentSnapshotModel<CloudflowExecutionModel> | DocumentSnapshotModel<CloudflowExecutionModel>,
      cloudflow: CloudflowWSnap | undefined
    ): CloudflowExecutionSnap => ({
      data: {
        ...data,
        customer: customer.ref,
        cloudflow,
      },
      ref: snapshot.ref,
    }),
    [customer.ref]
  );

  useEffect(() => {
    if (customer && cloudflows) {
      const newExecutions: CloudflowExecutionSnap[] = [];
      const cloudflowExecutionRefs = getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowExecutions")
        .where("customer", "==", customer.ref);

      cloudflowExecutionRefs.get().then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          const data = doc.data();

          const cloudflow = cloudflows.find((cloudFlow) => cloudFlow.ref.id === data.cloudflowId);

          newExecutions.push(cloudflowExecutionTransform(data, doc, cloudflow));
        });

        setCloudflowExecutions(newExecutions);
        setCloudflowExecutionsLoading(false);
      });
    }
  }, [cloudflows, customer, cloudflowExecutionTransform]);

  return { cloudflowExecutions, cloudflowExecutionsLoading };
};

export const useHasActiveCloudflowExecutions = (flowId: string) => {
  const cloudflowExecutionQuery = getCollection(CloudflowEngineModel)
    .doc("cloudflows")
    .collection("cloudflowExecutions")
    .where("cloudflowId", "==", flowId)
    .where("status", "in", [
      CloudflowExecutionStatus.RUNNING,
      CloudflowExecutionStatus.PENDING_APPROVAL,
      CloudflowExecutionStatus.PENDING,
    ]);
  const [executions] = useCollectionData(cloudflowExecutionQuery);
  return executions && executions.length > 0;
};

export const useAwsData = (accountId: string, shouldLoadRegions: boolean) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const errorSnackbar = useErrorSnackbar();
  const [regions, setRegions] = useState<string[]>([]);
  useEffect(() => {
    if (!customer?.id || !accountId) {
      return;
    }

    async function fetch() {
      if (!shouldLoadRegions) {
        return;
      }
      try {
        const response = await getAwsRegions(api, customer?.id, accountId);
        const data = response.data.map((region) => region.RegionName);
        setRegions(data);
      } catch (e) {
        errorSnackbar("Failed to fetch regions");
        setRegions([]);
      }
    }

    fetch();
  }, [accountId, api, customer?.id, errorSnackbar, shouldLoadRegions]);

  return { regions };
};

export const usePublishCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const publish = useCallback(
    async (customerId: string, flowId: string) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await publishCloudflow(api, customerId, flowId);
        track("Flow Published", { flowId, isPublished: true });
        successSnackbar("CloudFlow successfully published");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to publish CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [publish, loading] as const;
};

export const useUnpublishCloudflow = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const track = useTrackCloudflowEvent();

  const [loading, setLoading] = useState<boolean>(false);

  const unpublish = useCallback(
    async (customerId: string, flowId: string) => {
      let res: AxiosResponse<CloudflowDTO> | undefined;
      try {
        setLoading(true);
        res = await unpublishCloudflow(api, customerId, flowId);
        track("Flow Published", { flowId, isPublished: false });
        successSnackbar("CloudFlow successfully unpublished");
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to unpublish CloudFlow");
      } finally {
        setLoading(false);
      }
      return res;
    },
    [api, errorSnackbar, successSnackbar, track]
  );

  return [unpublish, loading] as const;
};

export const useGetGcpProjects = (customerId: string, organizationName: string) => {
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["gcpProjects", customerId, organizationName],
    async () => {
      try {
        const res: AxiosResponse<ProjectInfo[]> = await getGcpProjects(api, customerId, organizationName);
        return res.data;
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to retrieve GCP projects");
        throw e;
      }
    },
    {
      staleTime: 30 * 60 * 1000,
      enabled: Boolean(customerId && organizationName),
    }
  );

  return { data: data || [], isLoading, isError, error, refetch };
};

export const useGetCloudflow = ({
  customerId,
  flowId,
  withNodes,
}: {
  customerId: string;
  flowId: string;
  withNodes: boolean;
}) => {
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["getCloudflow", flowId],
    async () => {
      try {
        const res: AxiosResponse<CloudflowDTO> = await getCloudflow(api, customerId, flowId, withNodes);
        return res.data;
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Failed to retrieve cloudflow");
        throw e;
      }
    },
    {
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  return { data, isLoading, isError, error, refetch };
};

type EventTypes = {
  "Node Tested": NodeTestedEvent;
  "Flow Created": CloudflowCreatedEvent;
  "Node Created": NodeCreatedEvent;
  "Node Edited": NodeEditedEvent;
  "Flow Published": CloudflowPublishedEvent;
};

export function useTrackCloudflowEvent() {
  const { trackEvent } = useSegmentTrackEvent();

  return useCallback(
    <T extends keyof EventTypes>(type: T, options: EventTypes[T]) => {
      trackEvent(type, {
        pageType: "Governance",
        pageSubType: "CloudFlow",
        feature: "CloudFlow",
        ...options,
      });
    },
    [trackEvent]
  );
}
