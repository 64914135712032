import { useCallback, useEffect, useMemo, useState } from "react";

import { CloudFlowNodeType, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { FormControl, Link, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useGetGcpProjects } from "../../hooks";
import { useFieldValidationProps } from "./useFieldValidationProps";
import { cloudflowTexts } from "../../../../assets/texts";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";

const permissionsProjectIdKey = "permissionsProjectId";

const GCPPermissionForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const { nodeConfig, setActiveTab } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { customer } = useCustomerContext();
  const [gcpCloudConnectData] = useCloudConnectData(customer.id);
  const [organization, setOrganization] = useState<string>(formikProps?.values?.organization || "");
  const organizations = useMemo(
    () =>
      gcpCloudConnectData.flatMap(
        (gcpCloudConnect) =>
          gcpCloudConnect.data?.organizations?.map((org) => ({
            name: org.name,
            displayName: org.displayName,
            serviceAccount: gcpCloudConnect.data.clientEmail,
          })) || []
      ),
    [gcpCloudConnectData]
  );
  const { data: projects, isLoading: projectLoading } = useGetGcpProjects(customer.id, organization);

  const projectIdProps = useMemo(
    () => formikProps.getFieldProps(permissionsProjectIdKey),
    [formikProps, permissionsProjectIdKey]
  );
  const { showError, errorMessage } = useFieldValidationProps(formikProps, projectIdProps);

  const handleOrganizationChange = useCallback(
    async (organizationName: string) => {
      setOrganization(organizationName);
      const serviceAccount = organizations.find((org) => org.name === organizationName)?.serviceAccount;

      await Promise.all([
        formikProps.setFieldValue("organization", organizationName),
        formikProps.setFieldValue("serviceAccount", serviceAccount),
      ]);
    },
    [organizations, formikProps]
  );

  useEffect(() => {
    if (!organization && organizations.length > 0) {
      handleOrganizationChange(organizations[0]?.name);
    }
  }, [organization, organizations, handleOrganizationChange, formikProps.values.organization]);

  if (!organizations || organizations.length === 0) return null;

  const paramsProjectIdKey = (Object.keys(inputModel.members) || []).find(
    (value) => value !== "organization" && value !== "serviceAccount" && value !== permissionsProjectIdKey
  );
  const paramsProjectId = paramsProjectIdKey && nodeConfig?.parameters?.configurationValues?.[paramsProjectIdKey];

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <Typography variant="subtitle2">{cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS}</Typography>
      <Typography variant="body2" color={"text.secondary"}>
        {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS_DESCRIPTION}
      </Typography>
      <FormControl fullWidth>
        {organization ? (
          <Stack direction="row" gap={1} sx={{ mt: 1 }}>
            <Typography variant="body2">{cloudflowTexts.PERMISSIONS.ORGANIZATION}:</Typography>{" "}
            <Typography variant="subtitle2">
              {organizations.find((org) => org.name === organization)?.displayName}
            </Typography>
          </Stack>
        ) : (
          <TextField
            id="organization"
            name="organization"
            variant="outlined"
            select
            label="Organization"
            value={organization}
            onChange={(event) => {
              handleOrganizationChange(event.target.value);
            }}
            required
          >
            {organizations.map((org) => (
              <MenuItem key={org.name} value={org.name}>
                {org.displayName}
              </MenuItem>
            ))}
          </TextField>
        )}
      </FormControl>
      {paramsProjectIdKey ? (
        <Stack direction="row" gap={1}>
          <Typography variant="body2">{cloudflowTexts.PERMISSIONS.PROJECT_ID}:</Typography>{" "}
          {paramsProjectId ? (
            <Typography variant="subtitle2">{paramsProjectId as string}</Typography>
          ) : (
            <Typography variant="subtitle2">{cloudflowTexts.PERMISSIONS.NO_PROJECT_ID}</Typography>
          )}
        </Stack>
      ) : (
        <FormControl fullWidth>
          <VirtualizedAutocomplete
            loading={projectLoading}
            id={"project"}
            options={projects.map((project) => project.projectId)}
            onChange={(_, value) => {
              formikProps.setFieldValue(permissionsProjectIdKey, value || "");
            }}
            renderOption={(props, option) => (
              <MenuItem {...props} value={option}>
                {option}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                label="Project"
                name="Project"
                fullWidth
                error={showError}
                helperText={errorMessage}
                variant="outlined"
                required={true}
              />
            )}
            size="small"
            fullWidth
            value={formikProps?.values?.[permissionsProjectIdKey] || null}
          />
        </FormControl>
      )}
      {(paramsProjectIdKey || !organization) && (
        <Typography variant="body2" color={"text.secondary"}>
          {cloudflowTexts.PERMISSIONS.UPDATE_VALUES}{" "}
          <Link variant="body2" onClick={() => setActiveTab("Parameters")} style={{ cursor: "pointer" }}>
            {cloudflowTexts.PERMISSIONS.PARAMETERS_TAB}
          </Link>
        </Typography>
      )}
    </Stack>
  );
};

export default GCPPermissionForm;
